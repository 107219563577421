import React from 'react'
import { Box as GrommetBox } from 'grommet'
import { Box, Text, Grid } from '../print-components'
import { Address } from '../../../index'

interface Props {
  salesRepAddress: Address
  facilityAddress: Address
}

export const RestockDetails: React.FC<Props> = ({
  salesRepAddress,
  facilityAddress,
}) => {
  return (
    <Box>
      <Text boxTitle>Restock Details</Text>
      <Text boxSubtitle>Restock To</Text>
      <Grid columns={2} gap={10} repeat="1fr 1fr">
        <GrommetBox>
          <Text boxTitle>Restock: Rep</Text>
          <Text>{salesRepAddress.name}</Text>
          <Text>{salesRepAddress.street}</Text>
          <Text>
            {salesRepAddress.city}, {salesRepAddress.state}
          </Text>
          <Text>{salesRepAddress.zipCode}</Text>
          <Text>{salesRepAddress.phoneNumber}</Text>
        </GrommetBox>

        <GrommetBox>
          <Text boxTitle>Restock: Facility</Text>
          <Text>{facilityAddress.name}</Text>
          <Text>{facilityAddress.street}</Text>
          <Text>
            {facilityAddress.city}, {facilityAddress.state}{' '}
          </Text>
          <Text>{facilityAddress.zipCode}</Text>
          <Text>{facilityAddress.phoneNumber}</Text>
        </GrommetBox>
      </Grid>
    </Box>
  )
}
