import React from 'react'
import { Menu } from '@orx/ui/dist'
import { useAuth0 } from '@auth0/auth0-react'

export const LogoutMenu: React.FC = () => {
  const { isAuthenticated, user, logout } = useAuth0()

  if (isAuthenticated) {
    return (
      <Menu
        label={user.email}
        dropBackground="white"
        items={[
          {
            label: 'Logout',
            onClick: () => logout(),
          },
        ]}
      />
    )
  }

  return null
}
