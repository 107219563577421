import { gql } from '@apollo/client'

export const BILL_QUERY = gql`
  query bill($id: ID!) {
    bill(id: $id) {
      id
      vendorName
      vendorPhoneNumber
      vendorStreet
      vendorUnit
      vendorCity
      vendorState
      vendorZipCode
      surgeonFirstName
      surgeonLastName
      surgeonEmail
      surgeonPhoneNumber
      surgeonSpecialty
      surgeonCity
      surgeonState
      surgeonZipCode
      salesRepFirstName
      salesRepLastName
      salesRepEmail
      salesRepPhoneNumber
      salesRepStreet
      salesRepUnit
      salesRepCity
      salesRepState
      salesRepZipCode
      facilityName
      facilityPhoneNumber
      facilityStreet
      facilityCity
      facilityState
      facilityZipCode
      procedureDate
      externalFacilityCaseId
      status
      billProcedures {
        id
        procedureName
        description
        billProcedureImplants {
          id
          implantProductFamilyName
          implantName
          implantDescription
          implantRefId
          implantSizes {
            value
            dimension
            unitOfMeasure
          }
          implantExternalImplantId
          lotNumber
          expirationDate
          implantedPrice
          openedPrice
          wastedPrice
          restockTo
          implanted
          opened
          wasted
        }
      }
      billExtraItems {
        id
        name
        price
        description
      }
    }
  }
`
