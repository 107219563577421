/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { DataTableRow } from '../../implant-usage-table'
import { bill_bill_billProcedures_billProcedureImplants } from '../../../types/bill'
import { TableBox, Table, TotalCost, Text } from '../print-components'
import { UsageType } from '../../implant-usage-table'

interface Props {
  title: string
  billImplants: (bill_bill_billProcedures_billProcedureImplants | null)[]
  usage: UsageType[]
}

export const ImplantUsage: React.FC<Props> = ({
  billImplants,
  title,
  usage,
}) => {
  const tableRowData = billImplants
    .map((implant) => {
      return usage.reduce((acc: DataTableRow[], usage) => {
        if (implant![usage] > 0) {
          const usagePricing = {
            implanted: implant!.implantedPrice,
            opened: implant!.openedPrice,
            wasted: implant!.wastedPrice,
          }

          return [
            ...acc,
            {
              name: implant!.implantName || '',
              description: implant!.implantDescription || '',
              sizes: implant!.implantSizes || null,
              referenceNumber: implant!.implantRefId || '',
              lotNumber: implant!.lotNumber || '',
              expirationDate: implant?.expirationDate
                ? new Date(implant.expirationDate)
                : null,
              usage: usage,
              quantity: implant![usage],
              restock: implant!.restockTo || '',
              unitPrice: usagePricing[usage],
              extendedPrice: usagePricing[usage] * implant![usage],
            },
          ]
        }

        return acc
      }, [])
    })
    .flat(2)
    .map((row, i) => ({
      ...row,
      tableRowNumber: i + 1,
    }))

  const totalCost = tableRowData.reduce((acc, d) => (acc += d.extendedPrice), 0)

  return (
    <>
      <Text pageTitle>{title}</Text>
      <TableBox>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name/Description</th>
              <th>REF</th>
              <th>LOT</th>
              <th>EXP</th>
              <th>Usage</th>
              <th>QTY</th>
              <th>Restock</th>
              <th>Unit Price</th>
              <th>Extended Price</th>
            </tr>
          </thead>
          <tbody>
            {tableRowData.map((implant, index) => (
              <tr key={`${implant.referenceNumber}-${implant.usage}-${index}`}>
                <td>{implant.tableRowNumber}</td>
                <td>
                  {implant.name}{' '}
                  {implant.description && ` - ${implant.description}`}
                  <div>
                    {implant.sizes &&
                      implant.sizes.map((size, index) => (
                        <span key={index} style={{ fontSize: '10px' }}>
                          {size?.dimension} {size?.value} {size?.unitOfMeasure}{' '}
                        </span>
                      ))}
                  </div>
                </td>
                <td>{implant.referenceNumber}</td>
                <td>{implant.lotNumber}</td>
                <td>
                  {implant.expirationDate
                    ? implant.expirationDate.toLocaleDateString()
                    : ''}
                </td>
                <td>{implant.usage}</td>
                <td>{implant.quantity}</td>
                <td>{implant.restock}</td>
                <td>
                  $
                  {implant.unitPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  $
                  {implant.extendedPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <TotalCost>
          Total Cost:{' '}
          <span>
            $
            {totalCost.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </span>
        </TotalCost>
      </TableBox>
    </>
  )
}
