import React from 'react'
import { Box, Image } from 'grommet'
import { Text } from '@orx/ui/dist'
import Logo from './assets/logo.svg'

interface Props {
  title?: string
}

export const ORXBox: React.FC<Props> = ({ children, title = '' }) => {
  return (
    <Box background="white" elevation="1" round="xsmall" width="36.8rem">
      <Box pad="medium">
        <Box align="center">
          <Image fit="contain" alt="orx logo" src={Logo} />
        </Box>
        {title && (
          <Text
            textAlign="center"
            variant="xlarge-heading"
            margin={{ top: 'medium' }}
          >
            {title}
          </Text>
        )}
      </Box>
      <Box pad={{ horizontal: 'medium', bottom: 'large' }}>{children}</Box>
    </Box>
  )
}
