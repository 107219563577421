import React from 'react'
import { Box, BoxProps } from 'grommet'
import { getEdgeSizes } from '@orx/ui/dist/theme'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  @media only screen and (max-width: 1200px) {
    padding-left: ${getEdgeSizes().medium};
    padding-right: ${getEdgeSizes().medium};
  }
`

export const ResponsiveBox: React.FC<BoxProps> = ({ children, ...props }) => {
  return <StyledBox {...props}>{children}</StyledBox>
}
