import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import { Text } from '@orx/ui/dist'
import { useLocation, Redirect } from 'react-router-dom'
import { ORXBox } from '../../components'
import { Layout } from '../../layout'
import querystring from 'query-string'
import { restClient } from '../../rest-client'

const Access: React.FC = () => {
  const [redirectUser, setRedirectUser] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const { search } = useLocation()
  const parsedSearch = querystring.parse(search).billRecipientId || ''

  async function sendAccessLink(BillRecipientId: string) {
    try {
      await restClient.post('/bills/sendAccessEmail', {
        BillRecipientId,
      })
      setEmailSent(true)
    } catch (error) {
      console.error(error)
      setEmailError(true)
    }
  }

  useEffect(() => {
    if (!parsedSearch) {
      setRedirectUser(true)
    } else if (parsedSearch) {
      sendAccessLink(parsedSearch as string)
    }
  }, [search])

  // redirect to the user to root if there is no billId
  if (redirectUser) return <Redirect to="/" />

  if (emailError) {
    return (
      <Layout showMenu={false}>
        <Box flex="grow" justify="center" align="center">
          <ORXBox>
            <Text textAlign="center">You do not have access to this bill.</Text>
            <Text textAlign="center">
              If this is incorrect please contact someone at ORX.
            </Text>
          </ORXBox>
        </Box>
      </Layout>
    )
  }

  return (
    <Layout showMenu={false}>
      <Box flex="grow" justify="center" align="center">
        <ORXBox>
          {!emailSent ? (
            <Box
              pad={{
                left: 'large',
                right: 'large',
              }}
            >
              <Text textAlign="center">Sending access link email...</Text>
            </Box>
          ) : (
            <Text variant="body-copy" textAlign="center">
              An email with an access link to the bill has been sent to your
              email.
            </Text>
          )}
        </ORXBox>
      </Box>
    </Layout>
  )
}

export default Access
