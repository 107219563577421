import React, { useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import { Box } from 'grommet'
import { Text, Spinner } from '@orx/ui/dist'
import { NoDataIcon } from '@orx/ui/dist/icons'
import { COLOR_VALUES } from '@orx/ui/dist/theme'
import { ORXBox } from './components'
import { Layout } from './layout'
import { ApolloClient, NormalizedCacheObject, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { bearerToken, userRolesVar, createCache } from './apollo/cache'

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL })

export const ApolloWrapper: React.FC = ({ children }) => {
  const [accessToken, setAccessToken] = useState('')
  const [error, setError] = useState(false)
  const [awaitingToken, setAwaitingToken] = useState(true)
  const { hash } = useLocation()

  const {
    isAuthenticated,
    isLoading: auth0Loading,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0()

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently()
      const claims = await getIdTokenClaims()
      const roles = claims['http://orx.ai/roles']
      setAccessToken(token)
      bearerToken(token) // set token on global variable for axios rest client
      userRolesVar(roles)
    } catch (error) {
      console.error(error)
    } finally {
      setAwaitingToken(false)
    }
  }

  useEffect(() => {
    if (hash.includes('error')) {
      setError(true) // passwordless emails that fail will have a hash conrtaining an error
      setAwaitingToken(false)
    } else {
      getToken()
    }
  }, [isAuthenticated])

  const authLink = setContext(async (_, { headers, ...rest }) => {
    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    }
  })

  const client = new ApolloClient({
    cache: createCache(),
    link: authLink.concat(httpLink),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })

  if (auth0Loading || awaitingToken) {
    return (
      <Box fill justify="center" align="center" background="champagne">
        <Spinner />
        <Text margin={{ top: 'small' }}>Loading...</Text>
      </Box>
    )
  }

  if (error) {
    return (
      <Layout>
        <Box fill justify="center" align="center">
          <ORXBox>
            <Box
              width="120px"
              height="120px"
              align="center"
              justify="center"
              alignSelf="center"
              round="full"
              background={{ color: COLOR_VALUES['champagne-dark'] }}
              margin={{ bottom: 'medium' }}
            >
              <NoDataIcon size="60px" color="orx-purple" />
            </Box>
            <Text margin={{ bottom: 'medium' }} textAlign="center">
              Sorry, but you need to request a new access link to view this
              bill.
            </Text>
          </ORXBox>
        </Box>
      </Layout>
    )
  }

  return (
    <ApolloProvider client={client as ApolloClient<NormalizedCacheObject>}>
      {children}
    </ApolloProvider>
  )
}

export default ApolloWrapper
