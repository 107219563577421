import React from 'react'
import { Box } from 'grommet'
import { Card, Text } from '@orx/ui/dist'
import { Address } from '../../index'

interface Props {
  billingAddress: Address
  salesRepAddress: Address
  vendorAddress: Address
}

export const BillingDetailsCard: React.FC<Props> = ({
  billingAddress,
  salesRepAddress,
  vendorAddress,
}) => {
  return (
    <Card>
      <Box direction="row" justify="between" margin={{ bottom: '20px' }}>
        <Text variant="xlarge-heading">Billing Details</Text>
      </Box>
      <Box fill direction="row" gap="medium">
        <Box>
          <Text
            variant="body-copy-small"
            color={{ light: 'steel-grey', dark: 'white' }}
            margin={{ bottom: 'xsmall' }}
          >
            Bill To
          </Text>
          <Text>{billingAddress.name}</Text>
          <Text>{billingAddress.street}</Text>
          <Text>
            {billingAddress.city}, {billingAddress.state}
          </Text>
          <Text>{billingAddress.zipCode}</Text>
          <Text>{billingAddress.phoneNumber}</Text>
        </Box>
        <Box>
          <Text
            variant="body-copy-small"
            color={{ light: 'steel-grey', dark: 'white' }}
            margin={{ bottom: 'xsmall' }}
          >
            Vendor
          </Text>
          <Text>{vendorAddress.name}</Text>
          <Text>{vendorAddress.street}</Text>
          <Text>
            {vendorAddress.city}, {vendorAddress.state}
          </Text>
          <Text>{vendorAddress.zipCode}</Text>
          <Text>{vendorAddress.phoneNumber}</Text>
        </Box>
        <Box>
          <Text
            variant="body-copy-small"
            color={{ light: 'steel-grey', dark: 'white' }}
            margin={{ bottom: 'xsmall' }}
          >
            Restock: Rep Storage
          </Text>
          <Text>{salesRepAddress.name}</Text>
          <Text>{salesRepAddress.street}</Text>
          <Text>
            {salesRepAddress.city}, {salesRepAddress.state}
          </Text>
          <Text>{salesRepAddress.zipCode}</Text>
          <Text>{salesRepAddress.phoneNumber}</Text>
        </Box>
      </Box>
    </Card>
  )
}
