import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from '../auth/private-route'
import Welcome from './welcome'
import BillRedirect from './bill-redirect'
import Access from './access'
import Bill from './bill'
import NotFound from './404'

export default function Pages(): React.ReactElement {
  return (
    <Switch>
      <Route exact path="/" component={Welcome} />
      <Route path="/billredirect" component={BillRedirect} />
      <Route path="/access" component={Access} />
      <PrivateRoute path="/bill" component={Bill} />
      <Route component={NotFound} />
    </Switch>
  )
}
