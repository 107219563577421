import React from 'react'
import ReactDOM from 'react-dom'
import MobileBlocker from './mobile-blocker'
import Auth0Wrapper from './auth0-wrapper'
import ApolloWrapper from './apollo-wrapper'
import { Grommet } from 'grommet'
import { ORX_theme } from '@orx/ui/dist/theme'
import { ToastMessageProvider } from '@orx/ui/dist'
import { BrowserRouter } from 'react-router-dom'
import Pages from './pages'

ReactDOM.render(
  <React.StrictMode>
    <Grommet full theme={ORX_theme}>
      <MobileBlocker>
        <BrowserRouter>
          <Auth0Wrapper>
            <ApolloWrapper>
              <ToastMessageProvider>
                <Pages />
              </ToastMessageProvider>
            </ApolloWrapper>
          </Auth0Wrapper>
        </BrowserRouter>
      </MobileBlocker>
    </Grommet>
  </React.StrictMode>,
  document.getElementById('root')
)
