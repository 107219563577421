import React from 'react'
import { Box } from 'grommet'
import { Text } from '@orx/ui/dist'
import {
  bill_bill_billProcedures_billProcedureImplants,
  bill_bill_billExtraItems,
} from '../../types/bill'

interface Props {
  billImplants: (bill_bill_billProcedures_billProcedureImplants | null)[]
  extraItems: bill_bill_billExtraItems[]
}

export const OverviewDetails: React.FC<Props> = ({
  billImplants,
  extraItems,
}) => {
  const totalImplantCost = billImplants.reduce((total, implant) => {
    return (total +=
      implant?.implanted * implant?.implantedPrice +
      implant?.opened * implant?.openedPrice +
      implant?.wasted * implant?.wastedPrice)
  }, 0)

  const totalExtraItemCost = extraItems.reduce(
    (total, item) => (total += item.price),
    0
  )

  const billTotalColor = billImplants.length ? 'ice-grey' : undefined
  const extraItemsColor = billTotalColor ? 'white' : 'ice-grey'
  const totalCostColor =
    extraItemsColor &&
    billTotalColor &&
    billImplants.length &&
    extraItems.length
      ? 'ice-grey'
      : 'white'

  return (
    <Box background="white" round={{ size: 'small' }}>
      <Box
        pad={{
          top: 'small',
          bottom: 'small',
          left: 'medium',
          right: 'medium',
        }}
      >
        <Text variant="large-heading">Overview Details</Text>
      </Box>
      {billImplants.length > 0 && (
        <Box
          background="ice-grey"
          direction="row"
          justify="between"
          align="center"
          pad={{
            top: 'small',
            bottom: 'small',
            left: 'medium',
            right: 'medium',
          }}
        >
          <Box>
            <Text>Implant Cost</Text>
            <Text variant="body-copy-xsmall" color="orx-grey">
              Total Implant Cost
            </Text>
          </Box>
          <Text>
            $
            {totalImplantCost.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </Text>
        </Box>
      )}
      {extraItems.length > 0 && (
        <Box
          background={extraItemsColor}
          direction="row"
          justify="between"
          align="center"
          pad={{
            top: 'small',
            bottom: 'small',
            left: 'medium',
            right: 'medium',
          }}
        >
          <Box>
            <Text>Extra Item Cost</Text>
            <Text variant="body-copy-xsmall" color="orx-grey">
              Total Extra Item Cost
            </Text>
          </Box>
          <Text>
            $
            {totalExtraItemCost.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </Text>
        </Box>
      )}
      <Box
        background={totalCostColor}
        round={{ size: 'small', corner: 'bottom' }}
        direction="row"
        justify="between"
        align="center"
        pad={{
          top: 'small',
          bottom: 'small',
          left: 'medium',
          right: 'medium',
        }}
      >
        <Text>Total</Text>
        <Text>
          $
          {(totalImplantCost + totalExtraItemCost).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </Text>
      </Box>
    </Box>
  )
}
