import React from 'react'
import { Box, Layer } from 'grommet'
import {
  Button,
  IconButton,
  Text,
  TextAreaField,
  TextInputField,
} from '@orx/ui/dist'
import { CloseIcon, LockIcon } from '@orx/ui/dist/icons'
import { getBorderRadius } from '@orx/ui/dist/theme'
import { Formik, FormikProps, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

const StyledLayer = styled(Layer)`
  border-radius: ${getBorderRadius().xsmall};
`

const Form = styled.form`
  margin: 0;
`

interface Props {
  open: boolean
  close: () => void
  poNumber?: string
  resend?: boolean
}

interface FormikValues {
  poNumber: string
  sendTo: string
  invoiceTo: string
  subject: string
  body: string
}

const validationSchema: Yup.SchemaOf<FormikValues> = Yup.object()
  .shape({
    poNumber: Yup.string().required().defined(),
    sendTo: Yup.string().email('Invalid Email').required().defined(),
    invoiceTo: Yup.string().email('Invalid Email').required().defined(),
    subject: Yup.string().required().defined(),
    body: Yup.string().required().defined(),
  })
  .required()
  .defined()

export const PurchaseOrderModal: React.FC<Props> = ({
  open,
  close,
  poNumber = '',
  resend = true,
}) => {
  if (!open) return null

  const initialValues = {
    poNumber,
    sendTo: '',
    invoiceTo: '',
    subject: '',
    body: '',
  }

  return (
    <StyledLayer
      position="center"
      onClickOutside={close}
      onEsc={close}
      responsive={false}
    >
      <Box pad="medium" width="524px">
        <Box direction="row" justify="between" align="center">
          <Text variant="body-copy" textAlign="center">
            {!resend ? 'Add' : 'Resend'} Purchase Order #
          </Text>
          <IconButton
            icon={<CloseIcon />}
            size="small"
            a11yTitle="close"
            onClick={close}
          />
        </Box>
        <Box pad={{ vertical: 'small' }}>
          <Formik<FormikValues>
            validationSchema={validationSchema}
            initialValues={initialValues}
            // eslint-disable-next-line
            onSubmit={async (values: FormikValues) => {
              // make call here
            }}
          >
            {({
              values,
              dirty,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              submitForm,
              errors,
              isSubmitting,
              touched,
            }: FormikProps<FormikValues>) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  value={values.poNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('poNumber', e.target.value)
                  }
                  component={TextInputField}
                  label="Purchase Order # (required)"
                  disabled={resend}
                  reverse
                  icon={<LockIcon size="small" color="orx-green" />}
                />
                <Field
                  value={values.sendTo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('sendTo', e.target.value)
                  }
                  onBlur={() => setFieldTouched('sendTo', true)}
                  error={
                    touched.sendTo &&
                    errors.sendTo?.includes('Invalid') &&
                    errors.sendTo
                  }
                  component={TextInputField}
                  label="Send To (required)"
                />
                <Field
                  value={values.invoiceTo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('invoiceTo', e.target.value)
                  }
                  onBlur={() => setFieldTouched('invoiceTo', true)}
                  error={
                    touched.invoiceTo &&
                    errors.invoiceTo?.includes('Invalid') &&
                    errors.invoiceTo
                  }
                  component={TextInputField}
                  label="Invoice To (required)"
                />
                <Field
                  value={values.subject}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('subject', e.target.value)
                  }
                  component={TextInputField}
                  label="Subject (required)"
                />
                <Field
                  value={values.body}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('body', e.target.value)
                  }
                  component={TextAreaField}
                  label="Body (required)"
                />
                <Box direction="row" gap="small" justify="end">
                  <Button variant="secondary" label="Cancel" onClick={close} />
                  <Button
                    variant="primary"
                    label={!resend ? 'Recieve PO & Send' : 'Send'}
                    disabled={
                      isSubmitting || !dirty || Object.keys(errors).length > 0
                    }
                    onClick={submitForm}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </StyledLayer>
  )
}
