import React, { useRef } from 'react'
import { Box } from 'grommet'
import { Text, Spinner } from '@orx/ui/dist'
import {
  BillingDetailsCard,
  ExtraItems,
  HeaderArea,
  ImplantUsageTable,
  OverviewDetails,
  PrintComponent,
} from './components'
import { pageStyle } from './components/print-component/print-components'
import { useReactToPrint } from 'react-to-print'
import { useLocation, Redirect } from 'react-router-dom'
import { BILL_QUERY } from './query'
import { bill, billVariables } from './types/bill'
import { useQuery } from '@apollo/client'
import { Layout, ResponsiveBox } from '../../layout'

export interface Address {
  name: string
  street: string
  city: string
  state: string
  zipCode: string
  phoneNumber: string
}

export interface BillProcedures {
  id: string
  name: string
  description: string
}

interface LocationState {
  billId: string
}

const Bill: React.FC = () => {
  const location = useLocation<LocationState>()
  const componentRef = useRef<HTMLDivElement | null>(null)

  // if there is no billId within the location state return the user to route - this is different from 404 as the user should have never navigated to this page
  if (!location.state?.billId) {
    return <Redirect to="/" />
  }

  const { data, loading, error } = useQuery<bill, billVariables>(BILL_QUERY, {
    variables: { id: location.state.billId },
  })

  const handlePrint = useReactToPrint({
    documentTitle: `${data?.bill?.vendorName} - ${
      data?.bill?.surgeonFirstName
    } ${data?.bill?.surgeonLastName} - ${new Date(
      data?.bill?.procedureDate
    ).toLocaleDateString()} - ${new Date(
      data?.bill?.procedureDate
    ).toLocaleTimeString()}`,
    content: () => componentRef.current,
    pageStyle,
  })

  if (error) return <Text margin="auto">{error.message}</Text>

  if (loading) {
    return (
      <Box flex="grow" justify="center" align="center">
        <Spinner />
        <Text margin={{ top: 'small' }}>Loading...</Text>
      </Box>
    )
  }

  if (!data?.bill) return <Text margin="auto">Bill not found</Text>

  const billImplants = (data.bill.billProcedures || []).flatMap(
    (procedure) => procedure.billProcedureImplants
  )

  const billProcedures: BillProcedures[] = (data.bill.billProcedures || []).map(
    (procedure) => ({
      id: procedure.id || '',
      name: procedure.procedureName || '',
      description: procedure.description || '',
    })
  )

  const facilityAddress: Address = {
    name: data.bill.facilityName || '',
    street: data.bill.facilityStreet || '',
    city: data.bill.facilityCity || '',
    state: data.bill.facilityState || '',
    zipCode: data.bill.facilityZipCode || '',
    phoneNumber: data.bill.facilityPhoneNumber || '',
  }

  const vendorAddress: Address = {
    name: data.bill.vendorName || '',
    street: data.bill.vendorStreet || '',
    city: data.bill.vendorCity || '',
    state: data.bill.vendorState || '',
    zipCode: data.bill.vendorZipCode || '',
    phoneNumber: data.bill.vendorPhoneNumber || '',
  }

  const salesRepAddress: Address = {
    name: `${data.bill.salesRepFirstName} ${data.bill.salesRepLastName}`,
    street: data.bill.salesRepStreet || '',
    city: data.bill.salesRepCity || '',
    state: data.bill.salesRepState || '',
    zipCode: data.bill.salesRepZipCode || '',
    phoneNumber: data.bill.salesRepPhoneNumber || '',
  }

  const openedWastedImplants = billImplants.filter(
    (implant) => implant?.opened || implant?.wasted
  )

  return (
    <Layout>
      <Box>
        <HeaderArea
          billId={location.state.billId}
          billStatus={data.bill.status || ''}
          caseID={data.bill.externalFacilityCaseId || ''}
          procedureDate={data.bill.procedureDate || ''}
          surgeonFullName={`${data.bill.surgeonFirstName} ${data.bill.surgeonLastName}`}
          billProcedures={billProcedures}
          handlePrint={handlePrint}
        />
        <ResponsiveBox
          pad={{ horizontal: 'xxlarge', vertical: 'large' }}
          gap="large"
          flex="grow"
        >
          <ImplantUsageTable
            billImplants={billImplants}
            usage={['implanted']}
            title="Implant Used Breakdown"
          />
          {openedWastedImplants.length > 0 && (
            <ImplantUsageTable
              billImplants={openedWastedImplants}
              usage={['opened', 'wasted']}
              title="Implant Wasted/Not Used Breakdown"
            />
          )}
          {(data.bill.billExtraItems || []).length > 0 && (
            <ExtraItems extraItems={data.bill.billExtraItems || []} />
          )}
          <OverviewDetails
            billImplants={billImplants}
            extraItems={data.bill.billExtraItems || []}
          />
          <BillingDetailsCard
            billingAddress={facilityAddress}
            salesRepAddress={salesRepAddress}
            vendorAddress={vendorAddress}
          />
        </ResponsiveBox>
        <PrintComponent
          caseId={data.bill.externalFacilityCaseId || ''}
          ref={componentRef}
          billImplants={billImplants}
          openWastedImplants={openedWastedImplants}
          extraItems={data.bill.billExtraItems || []}
          procedureDate={data.bill.procedureDate || ''}
          billProcedures={billProcedures}
          facilityAddress={facilityAddress}
          vendorAddress={vendorAddress}
          salesRepAddress={salesRepAddress}
          salesRepEmail={data.bill.salesRepEmail || ''}
          surgeonFullName={`${data.bill.surgeonFirstName} ${data.bill.surgeonLastName}`}
        />
      </Box>
    </Layout>
  )
}

export default Bill
