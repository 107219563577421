/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { Box } from 'grommet'
import { Text, DataTable } from '@orx/ui/dist'
import TotalTableFooter from '../total-table-footer'
import {
  bill_bill_billProcedures_billProcedureImplants,
  bill_bill_billProcedures_billProcedureImplants_implantSizes,
} from '../../types/bill'
import styled from 'styled-components'

const Capatalize = styled.div`
  text-transform: capitalize;
`

export interface Sizes {
  dimension: string
  unitOfMeasure: string
  value: string
}

export interface DataTableRow {
  tableRowNumber?: number
  name: string
  description: string
  sizes:
    | (bill_bill_billProcedures_billProcedureImplants_implantSizes | null)[]
    | null
  referenceNumber: string
  lotNumber: string
  expirationDate: Date | null
  usage: string
  quantity: number
  restock: string
  unitPrice: number
  extendedPrice: number
}

export type UsageType = 'implanted' | 'opened' | 'wasted'

interface Props {
  billImplants: (bill_bill_billProcedures_billProcedureImplants | null)[]
  usage: UsageType[]
  title: string
}

export const ImplantUsageTable: React.FC<Props> = ({
  billImplants,
  usage,
  title,
}) => {
  const restockAbbrv = (restock: string | null): string => {
    switch (restock) {
      case 'No Restock':
        return 'No'
      case 'Rep Storage':
        return 'Rep'
      case 'Facility':
        return 'Fac'
      case 'Other':
        return 'Other'
      case null:
        return ''
      default:
        return ''
    }
  }

  const tableRowData = billImplants
    .map((implant) => {
      return usage.reduce((acc: DataTableRow[], usage) => {
        if (implant![usage] > 0) {
          const usagePricing = {
            implanted: implant!.implantedPrice,
            opened: implant!.openedPrice,
            wasted: implant!.wastedPrice,
          }

          const restockTo = restockAbbrv(implant!.restockTo)

          return [
            ...acc,
            {
              name: implant!.implantName || '',
              description: implant!.implantDescription || '',
              sizes: implant!.implantSizes || null,
              referenceNumber: implant!.implantRefId || '',
              lotNumber: implant!.lotNumber || '',
              expirationDate: implant?.expirationDate
                ? new Date(implant.expirationDate)
                : null,
              usage: usage,
              quantity: implant![usage],
              restock: restockTo,
              unitPrice: usagePricing[usage],
              extendedPrice: usagePricing[usage] * implant![usage],
            },
          ]
        }

        return acc
      }, [])
    })
    .flat(2)
    .map((row, i) => ({
      ...row,
      tableRowNumber: i + 1,
    }))

  const totalCost = tableRowData.reduce((acc, d) => (acc += d.extendedPrice), 0)

  return (
    <>
      <DataTable
        title={title}
        data={tableRowData}
        primaryKey="referenceNumber"
        columns={[
          {
            property: 'tableRowNumber',
            header: '#',
            align: 'center',
            size: 'xxsmall',
          },
          {
            property: 'name',
            header: 'Product Name / Description',
            align: 'start',
            // eslint-disable-next-line react/display-name
            render: ({ name, description, sizes }: DataTableRow) => (
              <>
                <Text>
                  {name} {description && ` - ${description}`}
                </Text>
                <Box direction="row" gap="xsmall">
                  {sizes &&
                    sizes.map((size, index) => (
                      <Text
                        variant="body-copy-xsmall"
                        color="steel-grey"
                        key={index}
                      >
                        {size?.dimension} {size?.value} {size?.unitOfMeasure}
                      </Text>
                    ))}
                </Box>
              </>
            ),
          },
          {
            property: 'referenceNumber',
            header: 'REF #',
            align: 'start',
          },
          {
            property: 'lotNumber',
            header: 'LOT #',
            align: 'start',
          },
          {
            property: 'expirationDate',
            header: 'EXP',
            align: 'start',
            render: ({ expirationDate }: DataTableRow) =>
              expirationDate ? expirationDate.toLocaleDateString() : '',
            size: 'xsmall',
          },
          {
            property: 'usage',
            header: 'Usage',
            align: 'start',
            // eslint-disable-next-line react/display-name
            render: ({ usage }: DataTableRow) => (
              <Capatalize>
                <Text color={usage === 'wasted' ? 'blue' : 'midnight-blue'}>
                  {usage}
                </Text>
              </Capatalize>
            ),
            size: 'xsmall',
          },
          {
            property: 'quantity',
            header: 'QTY',
            align: 'center',
            size: 'xxsmall',
          },
          {
            property: 'restock',
            header: 'Restock',
            align: 'center',
            size: 'xxsmall',
          },
          {
            property: 'unitPrice',
            header: 'Unit Price',
            align: 'start',
            render: ({ unitPrice }: DataTableRow) =>
              `$${unitPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`,
            size: 'xsmall',
          },
          {
            property: 'extendedPrice',
            header: 'Extended Price',
            align: 'start',
            render: ({ extendedPrice }: DataTableRow) =>
              `$${extendedPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`,
            size: 'xsmall',
          },
        ]}
      />
      <TotalTableFooter total={totalCost} />
    </>
  )
}
