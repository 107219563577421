import React from 'react'
import { Box as GrommetBox } from 'grommet'
import styled, { css } from 'styled-components'
import {
  COLOR_VALUES,
  typographyVariants,
  getEdgeSizes,
} from '@orx/ui/dist/theme'

// pageStyle prop on react-to-print fn
export const pageStyle = `
  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }

    .page-break {
      page-break-after: always;
    }

    body {
      margin: 0;
    }

    thead {
      display: table-header-group;
    }

    tfoot {
      display: table-footer-group;
    }
  }

  @page {
    margin: 10mm;
    size: landscape;
  }
`

// hide component from display
export const Hidden = styled.div`
  display: none;
`

// the content printable area
export const PrintArea = styled.div`
  font-family: 'MarkPro';

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    .page-break {
      display: block;
    }
  }
`

export const Text = styled.p`
  font-size: 13px;
  font-weight: ${typographyVariants['body-copy'].fontWeight};
  margin: 0;
  padding: 0;

  ${({ boxTitle }: { boxTitle: boolean }) =>
    boxTitle &&
    css`
      font-size: ${typographyVariants['body-copy-xsmall'].fontSize};
      font-weight: 700;
      color: ${COLOR_VALUES['steel-grey']};
      margin-bottom: 20px;
    `}

  ${({ boxSubtitle }: { boxSubtitle: boolean }) =>
    boxSubtitle &&
    css`
      font-size: ${typographyVariants['body-copy'].fontSize};
      margin-bottom: ${getEdgeSizes().xsmall};
    `}

  ${({ pageTitle }: { pageTitle: boolean }) =>
    pageTitle &&
    css`
      font-size: 28px;
      font-weight: 700;
      margin-bottom: ${getEdgeSizes().medium};
    `}
`

export const Box = styled.div`
  border: 1px solid ${COLOR_VALUES['ice-grey']};
  border-radius: 12px;
  padding: 20px;
`

export const Grid = styled.div`
  display: grid;
  grid-column-gap: ${({ gap }: { gap: number }) => `${gap ? gap : 0}`};
  grid-template-columns: ${({ repeat }: { repeat: string }) => `${repeat}`};
  height: 100%;
`

export const ImplantSizesBox = styled.div`
  display: flex;
  gap: 10px;
`

const DescriptionLabel = styled(Text)`
  color: ${COLOR_VALUES['steel-grey']};
`

const DescriptionText = styled(Text)`
  font-weight: 700;
`

export const DescriptionItem: React.FC<{
  label: string
  text: string
}> = ({ label, text }) => (
  <GrommetBox margin={{ bottom: `${getEdgeSizes().xsmall}` }}>
    <DescriptionLabel>{label}</DescriptionLabel>
    <DescriptionText>{text}</DescriptionText>
  </GrommetBox>
)

// borders on the box overflow in print view - remove borders
export const TableBox = styled(Box)`
  padding: 0;

  @media print {
    border: none;
  }
`

export const Table = styled.table`
  border-bottom: 1px solid ${COLOR_VALUES['ice-grey']};
  border-spacing: 0;
  margin-bottom: ${getEdgeSizes().small};
  width: 100%;

  thead tr th {
    font-size: 10px;
    padding-bottom: ${getEdgeSizes().small};
    border-bottom: 1px solid ${COLOR_VALUES['ice-grey']};
    text-align: left;
  }

  tbody {
    font-size: ${typographyVariants['body-copy-xsmall'].fontSize};

    tr:first-of-type td {
      padding-top: ${getEdgeSizes().small};
    }

    td {
      padding-bottom: ${getEdgeSizes().small};
      padding-right: ${getEdgeSizes().small};
    }
  }
`

export const TotalCost = styled(Text)`
  text-align: right;

  span {
    font-size: ${typographyVariants['body-copy'].fontSize};
    font-weight: 700;
  }
`
