import React from 'react'
import { Box as GrommetBox } from 'grommet'
import { Box, Text } from '../print-components'
import { Address } from '../../../index'

interface Props {
  facilityAddress: Address
  vendorAddress: Address
}

export const BillingDetails: React.FC<Props> = ({
  facilityAddress,
  vendorAddress,
}) => {
  return (
    <Box>
      <Text boxTitle>Billing Details</Text>
      <GrommetBox gap="small">
        <GrommetBox>
          <Text boxSubtitle>Bill To</Text>
          <Text>{facilityAddress.name}</Text>
          <Text>{facilityAddress.street}</Text>
          <Text>
            {facilityAddress.city}, {facilityAddress.state}{' '}
            {facilityAddress.zipCode}
          </Text>
          <Text>{facilityAddress.phoneNumber}</Text>
        </GrommetBox>
        <GrommetBox>
          <Text boxSubtitle>Vendor/Manufacturer</Text>
          <Text>{vendorAddress.name}</Text>
          <Text>{vendorAddress.street}</Text>
          <Text>
            {vendorAddress.city}, {vendorAddress.state} {vendorAddress.zipCode}
          </Text>
          <Text>{vendorAddress.phoneNumber}</Text>
        </GrommetBox>
      </GrommetBox>
    </Box>
  )
}
