/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { bill_bill_billExtraItems } from '../../../types/bill'
import { TableBox, Table, TotalCost, Text } from '../print-components'

interface Props {
  extraItems: bill_bill_billExtraItems[]
}

export const ExtraItems: React.FC<Props> = ({ extraItems }) => {
  const tableRowData = extraItems.map((item, index) => ({
    id: item.id,
    tableRowNumber: index + 1,
    name: item.name,
    description: item.description,
    price: item.price,
  }))

  const totalCost = tableRowData.reduce((acc, curr) => (acc += curr.price), 0)

  return (
    <>
      <Text pageTitle>Extra Items</Text>
      <TableBox>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Description</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {tableRowData.map((item) => (
              <tr key={item.id}>
                <td>{item.tableRowNumber}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>
                  $
                  {item.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <TotalCost>
          Total Cost:{' '}
          <span>
            $
            {totalCost.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </span>
        </TotalCost>
      </TableBox>
    </>
  )
}
