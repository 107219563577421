import React from 'react'
import { Header, Footer, HEADER_HEIGHT, FOOTER_HEIGHT } from '@orx/ui/dist'
import { Box } from 'grommet'
import { ResponsiveBox, LogoutMenu } from '.'
import { getEdgeSizes } from '@orx/ui/dist/theme'
import styled from 'styled-components'

// wrapper component to scale content to fill the screen. Utilizing <Box fill> was setting the Header height to 0
const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

const ResponsiveHeader = styled(Header)`
  @media only screen and (max-width: 1200px) {
    padding-left: ${getEdgeSizes().medium};
    padding-right: ${getEdgeSizes().medium};
  }
`

interface Props {
  header?: JSX.Element
  footer?: JSX.Element
  /**
   * @param showMenu displays the logout menu and user email
   * @default true
   */
  showMenu?: boolean
}

export const Layout: React.FC<Props> = ({
  header,
  footer,
  showMenu = true,
  children,
}) => {
  return (
    <Wrapper>
      {header || (
        <ResponsiveHeader
          hasLogo={true}
          logoPosition="start"
          justify="end"
          pad={{ horizontal: 'xxlarge' }}
        >
          <ResponsiveBox pad={{ horizontal: 'xxlarge' }}>
            {showMenu && <LogoutMenu />}
          </ResponsiveBox>
        </ResponsiveHeader>
      )}
      <Box
        as="main"
        height={{ min: `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})` }}
        flex="grow"
        background="champagne"
      >
        {children}
      </Box>
      {footer || <Footer />}
    </Wrapper>
  )
}
