import React from 'react'
import { Text, DescriptionItem } from '../print-components'
import { COLOR_VALUES, getEdgeSizes } from '@orx/ui/dist/theme'
import styled from 'styled-components'

export const HeaderSpacer = styled.div`
  height: 50px;
  // utilize margin for whitespace as table overflow does not preserve padding
  margin-bottom: ${getEdgeSizes().large};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR_VALUES['ice-grey']};

  position: fixed;
  height: 50px;
  width: 100%;
  top: 0;
`

const SalesRepInfo = styled.div`
  display: flex;
  gap: 20px;
`

const VendorName = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: 25.35px;
`

interface Props {
  vendorName: string
  salesRepName: string
  salesRepPhone: string
  salesRepEmail: string
  caseID: string // external case ID
}

export const PageHeader: React.FC<Props> = ({
  vendorName,
  salesRepName,
  salesRepPhone,
  salesRepEmail,
  caseID,
}) => {
  return (
    <Header>
      <VendorName>{vendorName}</VendorName>
      <SalesRepInfo>
        <DescriptionItem label="Case ID" text={caseID} />
        <DescriptionItem label="Sales Rep" text={salesRepName} />
        <DescriptionItem label="Contact Number" text={salesRepPhone} />
        <DescriptionItem label="Contact Email" text={salesRepEmail} />
      </SalesRepInfo>
    </Header>
  )
}
