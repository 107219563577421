import axios from 'axios'
import { bearerToken } from './apollo/cache'

const client = axios.create({
  baseURL: process.env.REACT_APP_REST_URL,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
  },
})

client.interceptors.request.use((req) => ({
  ...req,
  headers: {
    ...req.headers,
    authorization: `Bearer ${bearerToken()}`,
  },
}))

export const restClient = client
