import React from 'react'
import { Text } from '@orx/ui/dist'
import { Box } from 'grommet'
import { Layout } from '../../layout'

const NotFound: React.FC = () => {
  return (
    <Layout>
      <Box fill>
        <Box fill align="center" justify="center">
          <Box
            elevation="xsmall"
            width="30rem"
            background="white"
            pad="large"
            round="small"
          >
            <Text textAlign="center">Uh Oh! 404 Page Not Found.</Text>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default NotFound
