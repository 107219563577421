import React, { ChangeEvent } from 'react'
import { Box, Layer, Button as GrommetButton } from 'grommet'
import {
  ActionButton,
  Button,
  IconButton,
  Text,
  TextAreaField,
  TextInputField,
} from '@orx/ui/dist'
import { CloseIcon, TrashIcon } from '@orx/ui/dist/icons'
import { getBorderRadius } from '@orx/ui/dist/theme'
import { Formik, FormikProps, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

const StyledLayer = styled(Layer)`
  border-radius: ${getBorderRadius().xsmall};
`

const Form = styled.form`
  margin: 0;
`

const Underline = styled(Text)`
  text-decoration: underline;
`

interface Props {
  open: boolean
  close: () => void
}

interface FormikValues {
  facilityEmails: {
    email: string
    message: string
  }[]
}

const validationSchema: Yup.SchemaOf<FormikValues> = Yup.object()
  .shape({
    facilityEmails: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .trim()
          .email('Invalid Email')
          .min(1)
          .test('required-email', 'This is a required field', function (value) {
            if (value && value[0]) {
              return true
            }

            return this.createError({
              path: `${this.path}[0]`,
              message: 'This is a required field',
            })
          })
          .defined(),
        message: Yup.string(),
      })
    ),
  })
  .required()
  .defined()

export const ShareModal: React.FC<Props> = ({ open, close }) => {
  if (!open) return null

  return (
    <StyledLayer position="center" onClickOutside={close} onEsc={close}>
      <Box pad="medium" width="524px" height={{ max: '800px' }} overflow="auto">
        <Box flex="grow" gap="small">
          <Box direction="row" justify="between" align="center">
            <Text variant="body-copy">Do you want to share bill?</Text>
            <IconButton
              icon={<CloseIcon />}
              size="small"
              a11yTitle="close"
              onClick={close}
            />
          </Box>
          <Text variant="body-copy-small">
            Share a bill with anyone within your facility.
          </Text>
        </Box>
        <Formik<FormikValues>
          validationSchema={validationSchema}
          initialValues={{ facilityEmails: [{ email: '', message: '' }] }}
          onSubmit={async (values: FormikValues) => {
            // make call here
          }}
        >
          {({
            values,
            dirty,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            submitForm,
            isSubmitting,
          }: FormikProps<FormikValues>) => (
            <Form onSubmit={handleSubmit}>
              <FieldArray
                name="facilityEmails"
                render={({ push, remove }) => (
                  <>
                    {values.facilityEmails.map((email, index) => (
                      <Box
                        key={index}
                        gap="xsmall"
                        align="start"
                        direction="row"
                      >
                        <Box fill="horizontal">
                          <Field
                            name={`facilityEmails.${index}.email`}
                            component={TextInputField}
                            type="text"
                            label="Email"
                            value={email.email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(
                                `facilityEmails.${index}.email`,
                                e.target.value
                              )
                            }
                            onBlur={() => setFieldTouched('emails', true)}
                            error={
                              'facilityEmails' in errors &&
                              touched.facilityEmails &&
                              errors.facilityEmails &&
                              errors.facilityEmails[index]
                            }
                          />
                          <Field
                            name={`facilityEmails.${index}.message`}
                            component={TextAreaField}
                            value={email.message}
                            label="Message"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(
                                `facilityEmails.${index}.message`,
                                e.target.value
                              )
                            }
                          />
                        </Box>
                        <ActionButton
                          a11yTitle="remove"
                          margin={{ top: '42px' }} // height of label + 1/2 height of input
                          icon={<TrashIcon />}
                          onClick={() => remove(index)}
                          disabled={values.facilityEmails.length === 1}
                        />
                      </Box>
                    ))}
                    <GrommetButton
                      plain
                      onClick={() => push({ email: '', message: '' })}
                    >
                      <Underline
                        variant="body-copy-small"
                        color="blue"
                        display="inline"
                      >
                        + Add Another Recipient
                      </Underline>
                    </GrommetButton>
                  </>
                )}
              />
              <Box fill="horizontal" direction="row" justify="end" gap="small">
                <Button variant="secondary" label="Cancel" onClick={close} />
                <Button
                  variant="primary"
                  label="Share Bill"
                  disabled={
                    isSubmitting || !dirty || Object.keys(errors).length > 0
                  }
                  onClick={submitForm}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </StyledLayer>
  )
}
