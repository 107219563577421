import React from 'react'
import { Box } from 'grommet'
import { Text } from '@orx/ui/dist'
import { Layout } from '../../layout'
import { ORXBox } from '../../components'

const Welcome: React.FC = () => {
  return (
    <Layout>
      <Box flex="grow" justify="center" align="center">
        <ORXBox title="Welcome">
          <Text textAlign="center">
            Please use the provided access link in your email to view your
            bills.
          </Text>
        </ORXBox>
      </Box>
    </Layout>
  )
}

export default Welcome
