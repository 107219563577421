import React from 'react'
import { Image } from 'grommet'
import { Text } from '../print-components'
import Logo from '../assets/orx-logo.svg'
import { COLOR_VALUES, getEdgeSizes } from '@orx/ui/dist/theme'
import styled from 'styled-components'

export const FooterSpacer = styled.div`
  height: 100px;
  // utilize margin for whitespace as table overflow does not preserve padding
  margin-top: ${getEdgeSizes().large};
`

const PageFooter = styled.div`
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
`

const Line = styled.div`
  border-top: 1px solid ${COLOR_VALUES['ice-grey']};
  margin-bottom: ${getEdgeSizes().small};
`

const SigningText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FooterSubText = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${getEdgeSizes().large};
`

export const SignatureArea: React.FC = () => {
  return (
    <PageFooter>
      <Line />
      <SigningText>
        <Text>Hospital Authorized Signature</Text>
        <Text>Printed Name</Text>
        <Text>Date</Text>
      </SigningText>
      <FooterSubText>
        <Text>Powered by</Text>
        <Image src={Logo} alt="orx logo" margin={{ left: 'xsmall' }} />
        <div className="count"></div>
      </FooterSubText>
    </PageFooter>
  )
}
