import React, { Fragment } from 'react'
import { Box as GrommetBox } from 'grommet'
import { Box, Text, DescriptionItem } from '../print-components'
import { BillProcedures } from '../../../index'

interface Props {
  procedureDate: string
  surgeonName: string
  billProcedures: BillProcedures[]
}

export const CaseBillSummary: React.FC<Props> = ({
  procedureDate,
  surgeonName,
  billProcedures,
}) => {
  const formattedProcedureDate = procedureDate
    ? new Date(procedureDate).toLocaleDateString()
    : ''

  const formattedProcedureTime = procedureDate
    ? new Date(procedureDate).toLocaleTimeString()
    : ''

  return (
    <Box>
      <GrommetBox margin={{ bottom: 'small' }}>
        <DescriptionItem label="Surgeon" text={surgeonName} />
        <DescriptionItem
          label="Date of Surgery"
          text={formattedProcedureDate}
        />
        <DescriptionItem
          label="Time of Surgery"
          text={formattedProcedureTime}
        />
      </GrommetBox>
      <Text boxTitle>Procedure Summary</Text>
      <GrommetBox gap="small">
        {billProcedures.map((procedure) => (
          <Fragment key={procedure.id}>
            <Text>Procedure - {procedure.name}</Text>
            <Text>{procedure.description}</Text>
          </Fragment>
        ))}
      </GrommetBox>
    </Box>
  )
}
