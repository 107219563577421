import React from 'react'
import { DataTable } from '@orx/ui/dist'
import TotalTableFooter from '../total-table-footer'
import { bill_bill_billExtraItems } from '../../types/bill'

interface Props {
  extraItems: bill_bill_billExtraItems[]
}

export const ExtraItems: React.FC<Props> = ({ extraItems }) => {
  const tableRowData = extraItems.map((item, index) => ({
    tableRowNumber: index + 1,
    name: item.name,
    description: item.description || '',
    price: item.price,
  }))

  const totalCost = extraItems.reduce((acc, curr) => (acc += curr.price), 0)

  return (
    <>
      <DataTable
        title="Extra Items"
        primaryKey="#"
        data={tableRowData}
        columns={[
          {
            property: 'tableRowNumber',
            header: '#',
            align: 'center',
            size: 'xxsmall',
          },
          {
            property: 'name',
            header: 'Name',
            align: 'start',
          },
          {
            property: 'description',
            header: 'Description',
            align: 'start',
          },
          {
            property: 'price',
            header: 'Price',
            align: 'start',
            render: ({ price }: { price: number }) =>
              `$${price.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`,
          },
        ]}
      />
      <TotalTableFooter total={totalCost} />
    </>
  )
}
