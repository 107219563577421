import React from 'react'
import { Box, Button as GrommetButton } from 'grommet'
import { ResponsiveBox } from './responsive-box'
import { Button, FOOTER_HEIGHT } from '@orx/ui/dist'
import styled from 'styled-components'

const StickyBox = styled(Box)`
  position: sticky;
  bottom: 0;
`

interface Props {
  actions?: JSX.Element
  exit: () => void
  handlePrint: () => void
  handleShare: () => void
}

export const StickyFooter: React.FC<Props> = ({
  actions,
  exit,
  handlePrint,
  handleShare,
}) => {
  return (
    <StickyBox as="footer">
      <ResponsiveBox
        background="orx-purple"
        direction="row"
        justify="between"
        align="center"
        height={FOOTER_HEIGHT}
        pad={{ vertical: 'small', horizontal: 'xxlarge' }}
      >
        <Button
          variant="secondary"
          color="white"
          onClick={() => exit()}
          label="Exit"
        />
        <Box direction="row" gap="small">
          <GrommetButton plain label="Print" onClick={() => handlePrint()} />
          <Box border={{ side: 'right', size: '2px', color: 'white' }} />
          <GrommetButton plain label="Share" onClick={() => handleShare()} />
        </Box>
        {actions ? actions : <Box />}
      </ResponsiveBox>
    </StickyBox>
  )
}
