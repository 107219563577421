import React from 'react'
import { Box } from 'grommet'
import { Text } from '@orx/ui/dist'
import { SomethingWrongIcon } from '@orx/ui/dist/icons'
import { COLOR_VALUES } from '@orx/ui/dist/theme'

// blocks mobile devices from accessing the client portal
const MobileBlocker: React.FC = ({ children }) => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return (
      <Box
        fill
        background="champagne"
        gap="small"
        align="center"
        justify="center"
      >
        <Box
          width="280px"
          height="280px"
          align="center"
          justify="center"
          alignSelf="center"
          round="full"
          background={{ color: COLOR_VALUES['champagne-dark'] }}
        >
          <SomethingWrongIcon size="120px" color="orx-purple" />
        </Box>
        <Text textAlign="center" variant="xlarge-heading">
          Please use a desktop device to access the ORX Portal.
        </Text>
      </Box>
    )
  }

  return <>{children}</>
}

export default MobileBlocker
