import React from 'react'
import { Box as GrommetBox } from 'grommet'
import { Hidden, PrintArea, Grid, Text } from './print-components'
import {
  bill_bill_billProcedures_billProcedureImplants,
  bill_bill_billExtraItems,
} from '../../types/bill'
import { Address, BillProcedures } from '../../index'
import {
  BillingDetails,
  CaseBillSummary,
  ExtraItems,
  ImplantUsage,
  HeaderSpacer,
  FooterSpacer,
  PageHeader,
  SignatureArea,
  RestockDetails,
} from './components'
import { getEdgeSizes } from '@orx/ui/dist/theme'
import '@orx/ui/fonts/fonts.css' // need to load fonts for print iFrame which doesn't have Grommet loaded styled

interface Props {
  billImplants: (bill_bill_billProcedures_billProcedureImplants | null)[]
  billProcedures: BillProcedures[]
  caseId: string
  extraItems: bill_bill_billExtraItems[]
  facilityAddress: Address
  openWastedImplants: (bill_bill_billProcedures_billProcedureImplants | null)[]
  procedureDate: string
  vendorAddress: Address
  salesRepAddress: Address
  salesRepEmail: string
  surgeonFullName: string
}

// NOTE: Print layout https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
// eslint-disable-next-line react/display-name
export const PrintComponent = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      billImplants,
      billProcedures,
      caseId,
      extraItems,
      facilityAddress,
      openWastedImplants,
      procedureDate,
      vendorAddress,
      salesRepAddress,
      salesRepEmail,
      surgeonFullName,
    },
    ref
  ) => {
    return (
      <Hidden>
        <PrintArea ref={ref}>
          <PageHeader
            vendorName={vendorAddress.name}
            salesRepName={salesRepAddress.name}
            salesRepPhone={salesRepAddress.phoneNumber}
            salesRepEmail={salesRepEmail}
            caseID={caseId}
          />

          <SignatureArea />

          <table>
            <thead>
              <tr>
                <td>
                  <HeaderSpacer />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Text pageTitle>Case Summary</Text>
                  <Grid
                    columns={4}
                    repeat="1fr 1fr 2fr"
                    gap={getEdgeSizes().xsmall}
                  >
                    <CaseBillSummary
                      procedureDate={procedureDate}
                      surgeonName={surgeonFullName}
                      billProcedures={billProcedures}
                    />
                    <BillingDetails
                      facilityAddress={facilityAddress}
                      vendorAddress={vendorAddress}
                    />
                    <RestockDetails
                      salesRepAddress={salesRepAddress}
                      facilityAddress={facilityAddress}
                    />
                  </Grid>
                  <div className="page-break" />
                  <GrommetBox fill>
                    <ImplantUsage
                      billImplants={billImplants}
                      title="Implant Used Breakdown"
                      usage={['implanted']}
                    />
                  </GrommetBox>
                  <div className="page-break" />
                  {openWastedImplants.length > 0 && (
                    <ImplantUsage
                      billImplants={billImplants}
                      title="Implant Wasted/Not Used Breakdown"
                      usage={['opened', 'wasted']}
                    />
                  )}
                  {extraItems.length > 0 && (
                    <ExtraItems extraItems={extraItems} />
                  )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <FooterSpacer />
                </td>
              </tr>
            </tfoot>
          </table>
        </PrintArea>
      </Hidden>
    )
  }
)
