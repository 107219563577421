import React, { useState } from 'react'
import { Box } from 'grommet'
import { Text, Button, useToast } from '@orx/ui/dist'
import { PrintIcon, ApprovedIcon } from '@orx/ui/dist/icons'
import { BillProcedures } from '../../index'
import { useReactiveVar } from '@apollo/client'
import { userRolesVar } from '../../../../apollo/cache'
import { restClient } from '../../../../rest-client'
import { ResponsiveBox } from '../../../../layout'
import styled from 'styled-components'

const CaseIDText = styled(Text)`
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 1.375rem;
`

interface Props {
  billId: string
  billStatus: string
  caseID: string
  procedureDate: string
  surgeonFullName: string
  billProcedures: BillProcedures[]
  handlePrint: (() => void) | undefined // calls react-to-print method
}

export const HeaderArea: React.FC<Props> = ({
  billId,
  billStatus,
  caseID,
  procedureDate,
  surgeonFullName,
  billProcedures,
  handlePrint,
}) => {
  const userRoles = useReactiveVar(userRolesVar)
  const toast = useToast()
  const [isApproving, setIsApproving] = useState(false)
  const [billApproved, setbillApproved] = useState(billStatus === 'Approved')

  const handleApprove = async () => {
    setIsApproving(true)

    try {
      restClient.post(`/bills/${billId}/approve`)
      toast('Bill approved.', { variant: 'success' })
      setbillApproved(true)
    } catch (error) {
      console.error(error)
      toast('Bill could not be approved.', { variant: 'danger' })
    } finally {
      setIsApproving(false)
    }
  }

  return (
    <ResponsiveBox
      background="orx-purple"
      border={{ side: 'top', size: '2px', color: 'orx-green' }}
      gap="small"
      pad={{ horizontal: 'xxlarge', vertical: 'xlarge' }}
    >
      <Text>Bill Overview</Text>
      <CaseIDText>Case ID: {caseID}</CaseIDText>
      <Box direction="row" gap="xsmall" margin={{ top: 'xsmall' }} wrap>
        <Text>
          Date of Surgery:{' '}
          {new Date(new Date(procedureDate)).toLocaleDateString()}
        </Text>
        <Text>
          Start Time: {new Date(new Date(procedureDate)).toLocaleTimeString()}
        </Text>
        <Text>{surgeonFullName}</Text>
        {billProcedures.map((procedure) => (
          <Box
            key={procedure.id}
            direction="row"
            gap="xsmall"
            margin={{ bottom: 'xsmall' }}
          >
            <Text>{procedure.name}</Text>
            <Text>{procedure.description}</Text>
          </Box>
        ))}
      </Box>
      <Box direction="row" gap="small">
        {userRoles.includes('Biller') && (
          <Button
            icon={<ApprovedIcon color={billApproved ? 'orx-grey' : 'white'} />}
            label="Approve"
            variant="primary"
            onClick={handleApprove}
            disabled={billApproved || isApproving}
          />
        )}
        <Button
          icon={<PrintIcon />}
          label="Print"
          variant="primary"
          onClick={handlePrint}
        />
      </Box>
    </ResponsiveBox>
  )
}
