import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider, AppState } from '@auth0/auth0-react'

const Auth0Wrapper: React.FC = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || ''
  const history = useHistory()

  // the redirect callback is triggered after authentication and passing appState to redirect allows location to redirect
  const onRedirectCallback = (appState: AppState) => {
    history.push(
      appState?.targetUrl ? appState.targetUrl : (window.location.href = '/')
    )
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0Wrapper
