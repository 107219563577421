/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { RouteProps, Redirect } from 'react-router-dom'

interface Props extends RouteProps {
  component: React.FC
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...args }) => {
  const { isAuthenticated } = useAuth0()

  return (
    <Route
      render={() =>
        !isAuthenticated ? <Redirect to="/" /> : <Component {...args} />
      }
    />
  )
}

export default PrivateRoute
