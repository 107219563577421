import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import { Text } from '@orx/ui/dist'
import { useLocation, useHistory } from 'react-router-dom'
import { Layout } from '../../layout'
import NotFound from '../../pages/404'
import querystring from 'query-string'

const BillRedirect: React.FC = () => {
  const [billId, setBillId] = useState<string | undefined | null>(undefined)
  const { hash } = useLocation()
  const history = useHistory()

  useEffect(() => {
    const parsedHash = querystring.parse(hash)
    const state = parsedHash.state as string

    if (state) {
      const billId = state.split('/bills/')[1]

      if (billId) {
        history.push('/bill', { billId })
      } else {
        setBillId(null)
      }
    }
  }, [hash])

  if (billId === null) {
    return <NotFound />
  }

  return (
    <Layout>
      <Box flex="grow" justify="center" align="center">
        <Box
          elevation="xsmall"
          width="30rem"
          background="white"
          pad="large"
          round="small"
        >
          <Text textAlign="center">Redirecting...</Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default BillRedirect
