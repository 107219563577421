import React from 'react'
import { Box } from 'grommet'
import { Text } from '@orx/ui/dist'

interface Props {
  total: number
}

const TotalTableFooter: React.FC<Props> = ({ total }) => {
  return (
    <Box
      background="ice-grey"
      margin={{ top: 'xsmall' }}
      pad={{
        top: 'small',
        bottom: 'small',
        left: 'medium',
        right: 'medium',
      }}
      round={{ size: 'small', corner: 'bottom' }}
      border={[{ size: 'xsmall', side: 'top', color: 'ice-grey' }]}
      direction="row"
      justify="end"
      align="center"
      gap="small"
    >
      <Text variant="body-copy-small">Total Cost:</Text>
      <Text textAlign="end" variant="large-heading">
        ${total.toLocaleString(undefined, { minimumFractionDigits: 2 })}
      </Text>
    </Box>
  )
}

export default TotalTableFooter
